<template>
  <section class="jci_projects_apply container970">
    <div class="top-banner">
      <img v-if="skillCourseInfo.detail_picture" :src="skillCourseInfo.detail_picture"
        style="width: 100%; height: 100%;object-fit:cover" />
        <el-skeleton-item v-if="loading" variant="image" style="width: 100%; height: 230px;background:rgba(255, 255, 255, .2)" />
      <!-- <img v-else src="../../assets/images/don
      ation/second_bg.png" style="width: 100%; height: 100%;object-fit:cover" /> -->
      <div class="project_name" style="padding:0 100px;text-align: center;">
        <h3>{{ skillCourseInfo.project_name }}</h3>
      </div>
    </div>

    <el-card class="table-card mt-3 detail_card" style="padding-top:20px">
      <div class="option_back">
        <el-button type="primary" icon="el-icon-arrow-left" plain @click="backToGallery">Back</el-button>
      </div>
      <section class="d-flex justify-content-between align-items-center mb-3 mt-2">
        <h4>Project Details</h4>
        <div class="my_tag" v-if='skillCourseInfo.is_has_tag'>
          <img src="@/assets/images/public/project_zan.png" alt="">
          <span class="icon_text">Endorsed by the President</span>
        </div>
      </section>
      <section class="basic_info" style="margin-bottom: 40px;">
        <div class="item d-flex mb-2">
          <span class="left">Publisher</span>
          <div class="right">{{skillCourseInfo.publisher|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Area</span>
          <div class="right">{{skillCourseInfo.area|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">National Organization</span>
          <div class="right">{{skillCourseInfo.national|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2 description">
          <span class="left">Local Organization</span>
          <div class="right">{{skillCourseInfo.local|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2 description">
          <span class="left">Contact person for the project</span>
          <div class="right">{{skillCourseInfo.contact|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Duration of the project</span>
          <div class="right">{{skillCourseInfo.duration|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">RISE Pillars category</span>
          <div class="right">{{skillCourseInfo.project_category_desc|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Areas of Opportunity category</span>
          <div class="right">{{skillCourseInfo.area_category_desc|filterNull}}</div>
        </div>
        <div class="item d-flex">
          <span class="left">Year of the project</span>
          <div class="right">{{skillCourseInfo.year|filterNull}}</div>
        </div>
      </section>
      <section class="mb-3">
        <h4>Project Manager</h4>
      </section>
      <section class="basic_info">
        <div class="item d-flex mb-2">
          <span class="left">Project Manager name</span>
          <div class="right">{{skillCourseInfo.project_manager_name|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Contact email or phone number</span>
          <div class="right">{{skillCourseInfo.whatsApp_number|filterNull}}</div>
        </div>
        <div class="item d-flex  mb-5">
          <span class="left">Picture of the Project Manager</span>
          <div class="right" v-if="skillCourseInfo.project_manager_photo">
            <el-image :src="skillCourseInfo.project_manager_photo"></el-image>
          </div>
          <div class="right" v-else>-</div>
        </div>
        <div class="item d-flex flex-column mb-3">
          <span class="left_summary">Summary of the project</span>
          <div class="right" v-html="skillCourseInfo.summary"></div>
        </div>
        <!-- <div class="item d-flex flex-column mb-3">
          <span class="left_summary">What were the primary objectives of the project?</span>
          <div class="right" v-html="skillCourseInfo.objective"></div>
        </div>
        <div class="item d-flex flex-column mb-3">
          <span class="left_summary">Please list the key milestones or achievements accomplished during the
            project.</span>
          <div class="right" v-html="skillCourseInfo.achievement"></div>
        </div>
        <div class="item d-flex flex-column mb-3">
          <span class="left_summary">Describe the positive impact the project had on the organization, its stakeholders,
            or the community.</span>
          <div class="right" v-html="skillCourseInfo.positive_impact"></div>
        </div> -->
      </section>
    </el-card>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        skillCourseInfo: {
          detail_picture: ''
        },
        loading: true,
      }
    },

    components: {},

    mounted() {
      this.getProjectDetail()
    },

    methods: {
      backToGallery() {
        this.$store.commit("Public/CHANGE_PAGE", this.$route.query.page)
        if (this.$route.query.is_user) {
          if (this.$route.query.is_draft) {
            this.$store.commit("Public/CHANGE_DRAFT", 'gallery')
          }
          this.$router.back()
        } else {
          this.$store.commit("Public/CHANGE_LIST", 'gallery')
          this.$router.push(`/project-gallery`)
        }
      },

      // 获取项目详情
      getProjectDetail() {
        this.loading = true
        this.$http.getProjectDetail({ id: this.$route.query.id }).then((res) => {
          if (res.status == 200) {
            this.skillCourseInfo = res.data
          }
          setTimeout(() => {
            this.loading = false
          }, 0);
        })
      }

    }
  }
</script>
<style lang="scss" scoped>
  .jci_projects_apply {
    .height_scroll {
      max-height: 700px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .my_tag {
      border: 1.5px solid #EDBE38;
      border-radius: 14.5px;
      padding: 2px 8px;
      text-align: center;
      color: #EDBE38;
      line-height: 24px;
    }

    .top-banner {
      height: 230px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      position: relative;

      div {
        height: 230px;
        width: 100%;
        background: rgba($color: #999, $alpha: 0.3);
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        position: absolute;

        >h3 {
          color: #fff;
          font-size: 30px;
        }
      }


    }

    .detail_card {
      position: relative;
      overflow: visible;

      .option_back {
        position: absolute;
        display: flex;
        left: -120px;
        top: -1px;
      }
    }

    /deep/ .basic_info {
      .left {
        width: 260px;
      }

      .right {
        color: #999;
        flex: 1;

        ::v-deep p {
          margin: 0 !important;
          padding: 0 !important;
        }

        img {
          width: 183px;
          height: 183px;
        }
      }

      .description {

        ul,
        ol {
          padding: 0;
          margin-bottom: 2px;
        }

        ul {
          list-style: disc;
        }
      }
    }

    ::v-deep .table-card .el-card__body {
      padding-top: 0px;
    }

  }

  @media (max-width: 600px) {
    .jci_projects_apply {
      /deep/ .basic_info .item {
        flex-direction: column;
      }

      h4 {
        font-size: 1.2rem;
      }

      .my_tag {
        border: none;
      }

      .icon_text {
        display: none;
      }

      .project_name {
        padding: 0 1.2rem !important;

        h3 {
          font-size: 1.5rem !important;
          word-break: keep-all !important;
        }
      }
    }
  }
</style>